export * from './bookings'
export * from './payments'

export const PAGE_TYPES = {
	DIFITAL_DOWNLOAD: 'digital-download',
	MEETING: 'meeting',
	LEAD_MAGNET: 'lead-magnet',
	THANK_YOU: 'thank-you',
	FULFILLMENT: 'fulfillment',
	MEMBERSHIP: 'membership',
	LINK: 'link',
	STAN_REFERRAL_LINK: 'stan-referral-link',
	COURSE: 'course',
	WEBINAR: 'webinar',
}

export const PAGE_ERROR_CODES = {
	INVALID_FORM: 'G00',
	FAN_NAME: 'G04',
	FAN_EMAIL: 'G01',
	FORM_SUBMITTING: 'G02',
	NO_STOCK: 'G03',
	ACCEPT_TC: 'TC01',
	UNAPPLIED_DC: 'DC01',
	BOOKING_TIME: 'M01',
	BOOKING_DATE: 'M02',
	BOOKING_START_TIME: 'M03',
	BOOKING_END_TIME: 'M04',
	BOOKING_TAKEN: 'M05',
	WEBINAR_MISSING_DETAILS: 'W00',
	WEBINAR_NO_TIMES_AVAILABLE: 'W01',
	WEBINAR_TIME_NOT_SELECTED: 'W02',
	PAYMENT_ERROR: 'P00',
	PAYMENT_ERROR_RECEIVED_BLOCK: 'P01',
	PAYMENT_ERROR_SUCCESS_BLOCK: 'P02',
	TAX: 'T00',
	REQUEST_GENERAL_ERROR: 'R00',
	INVALID_NAME: 'I01',
	INVALID_EMAIL: 'I02',
	INVALID_QUESTION: 'I03',
}

export const CODE_TO_ERROR_MESSAGE = {
	G00: { title: 'Checkout incomplete', text: 'Form is missing information', type: 'error' },
	G01: { title: 'Checkout incomplete', text: 'Email field is missing', type: 'error' },
	G02: { title: 'Something went wrong', text: 'Please email friends@stanwith.me for help.', type: 'error' },
	G03: { title: 'Currently unavailable', text: 'This item is sold out. Please try again later', type: 'error' },
	G04: { title: 'Checkout incomplete', text: 'Name field is missing', type: 'error' },
	TC01: { title: 'Checkout incomplete', text: 'Please accept Terms and Conditions', type: 'error' },
	DC01: { title: 'Checkout incomplete', text: 'You have an unapplied discount code.', type: 'warn' },
	M01: { title: 'Booking incomplete', text: 'Please select a booking time', type: 'error' },
	M02: { title: 'Booking incomplete', text: 'Please select a booking date', type: 'error' },
	M03: { title: 'Booking incomplete', text: 'Invalid start time, please choose another time slot', type: 'error' },
	M04: { title: 'Booking incomplete', text: 'Please choose another time slot', type: 'error' },
	M05: { title: 'Booking incomplete', text: 'Time slot taken, please select a new time slot', type: 'error' },
	T00: { title: 'Checkout incomplete', text: 'Please provide a valid address', type: 'error' },
	W00: { title: 'Checkout incomplete', text: 'Please fill in missing form details', type: 'error' },
	W01: { title: 'No available times', text: 'Please check back later for upcoming events!', type: 'error' },
	W02: { title: 'Checkout incomplete', text: 'Please select a webinar time slot', type: 'error' },
	I01: { title: 'Invalid Name', text: 'Name field is invalid', type: 'error' },
	I02: { title: 'Invalid Email', text: 'Email field is invalid', type: 'error' },
	I03: { title: 'Invalid Answer', text: 'Form has invalid answers', type: 'error' },
}

export const TAG_TYPES_LIST = ['aid']
