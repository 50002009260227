<template>
	<svg
		v-if="name && icons[name]"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		v-html="icons[name]"
		:style="style"
	></svg>
</template>
<script>
	export default {
		props: {
			name: { type: String },
			scale: { type: Number, default: 1 },
			xoffset: { type: Number, default: 0 },
			yoffset: { type: Number, default: 0 },
		},
		data() {
			return {
				icons: {
					'digital-download':
						'<path d="M4.83594 9.56445L12.1829 15.5292L19.5298 9.56445" stroke="white" stroke-width="2.4" stroke-linecap="round" stroke-linejoin="round"/><path d="M12.1797 1L12.1797 15.4109" stroke="white" stroke-width="2.4" stroke-linecap="round"/><path d="M3 21H21" stroke="white" stroke-width="2.4" stroke-linecap="round"/>',
					'lock-solid':
						'<path d="M4.47656 11.6013C4.47656 10.5756 5.30804 9.74414 6.33373 9.74414H17.8862C18.9119 9.74414 19.7434 10.5756 19.7434 11.6013V19.6424C19.7434 20.6681 18.9119 21.4996 17.8862 21.4996H6.33373C5.30804 21.4996 4.47656 20.6681 4.47656 19.6424V11.6013Z" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M12.1108 3.85717C10.2815 3.85717 8.79857 5.34009 8.79857 7.16938V11.4102H6.94141V7.16938C6.94141 4.31441 9.25581 2 12.1108 2C14.9657 2 17.2802 4.31441 17.2802 7.16937V11.4102H15.423V7.16937C15.423 5.34009 13.9401 3.85717 12.1108 3.85717Z" fill="white"/>',
					play: '<path d="M19.526 11.1951L7.54296 4.1458C6.87127 3.75066 6 4.20518 6 4.95072V19.0493C6 19.7948 6.87127 20.2493 7.54296 19.8542L19.526 12.8049C20.158 12.4332 20.158 11.5668 19.526 11.1951Z" fill="white"/>',
					close: '<circle cx="12" cy="12" r="8.25" stroke="#131F60" stroke-width="1.5"/><path d="M8.81812 9.07544L15.1821 15.4394" stroke="#131F60" stroke-width="1.5" stroke-linecap="round"/><path d="M15.1821 9.07544L8.81817 15.4394" stroke="#131F60" stroke-width="1.5" stroke-linecap="round"/>',
					'close-no-border':
						'<svg data-v-577abeb6="" fill="none" viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg" class="close-icon"><path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M6.51983 6.5199C6.81273 6.22701 7.2876 6.22701 7.58049 6.5199L17.48 16.4194C17.7729 16.7123 17.7729 17.1872 17.48 17.4801C17.1871 17.7729 16.7122 17.7729 16.4193 17.4801L6.51983 7.58056C6.22694 7.28767 6.22694 6.8128 6.51983 6.5199Z" fill="#131F60"></path><path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M6.51996 17.4801C6.22707 17.1872 6.22707 16.7123 6.51996 16.4194L16.4195 6.51995C16.7124 6.22706 17.1872 6.22706 17.4801 6.51995C17.773 6.81285 17.773 7.28772 17.4801 7.58061L7.58062 17.4801C7.28773 17.773 6.81286 17.773 6.51996 17.4801Z" fill="#131F60"></path></svg>',
					warning:
						'<path fill-rule="evenodd" clip-rule="evenodd" d="M12 19.5C16.1421 19.5 19.5 16.1421 19.5 12C19.5 7.85786 16.1421 4.5 12 4.5C7.85786 4.5 4.5 7.85786 4.5 12C4.5 16.1421 7.85786 19.5 12 19.5ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#131F60"/><path d="M11.5097 12.5485L10.6183 8.09164C10.5564 7.78224 10.793 7.49358 11.1086 7.49358H12.8913C13.2068 7.49358 13.4435 7.78224 13.3816 8.09164L12.4902 12.5485C12.383 13.0844 11.6168 13.0844 11.5097 12.5485Z" fill="#131F60"/><path d="M13.1263 15.6323C13.1263 16.2542 12.6222 16.7583 12.0003 16.7583C11.3785 16.7583 10.8744 16.2542 10.8744 15.6323C10.8744 15.0105 11.3785 14.5063 12.0003 14.5063C12.6222 14.5063 13.1263 15.0105 13.1263 15.6323Z" fill="#131F60"/>',
				},
			}
		},
		computed: {
			style() {
				return { transform: `scale(${this.scale}) translate(${this.xoffset}px, ${this.yoffset}px)` }
			},
		},
	}
</script>
