<template>
	<div>
		<div class="layout-rect" v-if="!countryDropdown">
			<div class="phone-holder">
				<div class="input-bar phone-country dropbtn justify-content-center" @click="switchCountryDropdown" :class="{ disabled }">
					<div class="dropdown">
						<div class="d-flex flex-wrap align-items-center">
							<div v-if="selectedCountryCodeItem.country != 'Other'" class="country-2-letter-code">
								{{ selectedCountryCodeItem.code }}
							</div>
							<div v-if="selectedCountryCodeItem.country != 'Other'">{{ selectedCountryCodeItem.countryCode }}</div>
						</div>
					</div>
				</div>
				<input
					id="inputPhoneId"
					ref="inputPhone"
					v-model="localPhone"
					type="tel"
					placeholder="Enter your phone number"
					class="input-bar phone-number"
					:disabled="disabled"
					maxlength="13"
				/>
			</div>
		</div>
		<div ref="countryDropdown" class="country-menu" v-if="countryDropdown">
			<div class="dropdown-content" id="phoneCountryDropdown">
				<div @click="switchCountryDropdown" class="d-flex flex-wrap justify-content-between align-items-center" style="cursor: pointer">
					<div class="country-back-button">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.95 11" xmlns:xlink="http://www.w3.org/1999/xlink" :stroke="'black'">
							<path d="M7.05852 9.5L2.94138 5L7.05852 0.5" fill="none" stroke-width="1"></path>
						</svg>
					</div>
					<div class=""><b>Select Country</b></div>
					<div class="country-back-button"></div>
				</div>
				<div class="" style="margin-top: 10px; margin-bottom: 10px">
					<input class="input-bar" type="text" autofocus v-model="searchCountry" placeholder="Search country" />
				</div>
				<div v-for="(item, index) in clearCountryCodes">
					<div class="d-flex flex-wrap flag-selector-item align-items-center" @click="selectCountry(index)" v-if="isShowCountry(item)">
						<div class="country text-truncate" style="margin-right: auto; margin-left: 5px">{{ item.country }}</div>
						<div v-if="item.country != 'Other'">{{ item.countryCode }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import vClickOutside from 'v-click-outside'

	export default {
		directives: {
			clickOutside: vClickOutside.directive,
		},
		name: 'TelephoneInputLazy',
		components: {},
		props: {
			phone: {
				type: String,
				default: '',
			},
			phoneCountryCode: {
				type: String,
				default: '+1',
			},
			debounce: {
				type: Number,
				default: 2000,
			},
			disabled: Boolean,
		},
		computed: {
			selectedCountryCodeItem() {
				return this.clearCountryCodes[this.countryCodeSelectedIndex]
			},
			derivedPhoneNumber() {
				return this.selectedCountryCodeItem.countryCode + this.localPhone
			},
			storePrimaryColor() {
				return document.body.style.getPropertyValue('--stan-store-theme-primary-color') || 'black'
			},
			clearCountryCodes() {
				return this.countryCodes
			}
		},
		data() {
			return {
				isValid: false,
				isError: false,
				inputReady: false,
				libphonenumber: null,
				debounceTimeout: null,
				placeHolder: '',
				localPhone: '',
				searchCountry: '',
				countryDropdown: false,
				countryCodeSelectedIndex: 0,
				countryCodes: [
					{ countryCode: '+1', code: 'US', country: 'United States' },
					{ countryCode: '+61', code: 'AU', country: 'Australia' },
					{ countryCode: '+43', code: 'AT', country: 'Austria' },
					{ countryCode: '+1', code: 'CA', country: 'Canada' },
					{ countryCode: '+49', code: 'DE', country: 'Germany' },
					{ countryCode: '+33', code: 'FR', country: 'France' },
					{ countryCode: '+39', code: 'IT', country: 'Italy' },
					{ countryCode: '+31', code: 'NL', country: 'Netherlands' },
					{ countryCode: '+32', code: 'BE', country: 'Belgium' },
					{ countryCode: '+55', code: 'BR', country: 'Brazil' },
					{ countryCode: '+359', code: 'BG', country: 'Bulgaria' },
					{ countryCode: '+357', code: 'CY', country: 'Cyprus' },
					{ countryCode: '+420', code: 'CZ', country: 'Czech Republic' },
					{ countryCode: '+45', code: 'DK', country: 'Denmark' },
					{ countryCode: '+372', code: 'EE', country: 'Estonia' },
					{ countryCode: '+358', code: 'FI', country: 'Finland' },
					{ countryCode: '+30', code: 'GR', country: 'Greece' },
					{ countryCode: '+852', code: 'HK', country: 'Hong Kong' },
					{ countryCode: '+36', code: 'HU', country: 'Hungary' },
					{ countryCode: '+91', code: 'IN', country: 'India' },
					{ countryCode: '+62', code: 'ID', country: 'Indonesia' },
					{ countryCode: '+353', code: 'IE', country: 'Ireland' },
					{ countryCode: '+972', code: 'IL', country: 'Israel' },
					{ countryCode: '+81', code: 'JP', country: 'Japan' },
					{ countryCode: '+371', code: 'LV', country: 'Latvia' },
					{ countryCode: '+370', code: 'LT', country: 'Lithuania' },
					{ countryCode: '+352', code: 'LU', country: 'Luxembourg' },
					{ countryCode: '+60', code: 'MY', country: 'Malaysia' },
					{ countryCode: '+356', code: 'MT', country: 'Malta' },
					{ countryCode: '+52', code: 'MX', country: 'Mexico' },
					{ countryCode: '+64', code: 'NZ', country: 'New Zealand' },
					{ countryCode: '+234', code: 'NG', country: 'Nigeria' },
					{ countryCode: '+47', code: 'NO', country: 'Norway' },
					{ countryCode: '+63', code: 'PH', country: 'Philippines' },
					{ countryCode: '+48', code: 'PL', country: 'Poland' },
					{ countryCode: '+351', code: 'PT', country: 'Portugal' },
					{ countryCode: '+40', code: 'RO', country: 'Romania' },
					{ countryCode: '+65', code: 'SG', country: 'Singapore' },
					{ countryCode: '+421', code: 'SK', country: 'Slovakia' },
					{ countryCode: '+386', code: 'SI', country: 'Slovenia' },
					{ countryCode: '+27', code: 'ZA', country: 'South Africa' },
					{ countryCode: '+34', code: 'ES', country: 'Spain' },
					{ countryCode: '+46', code: 'SE', country: 'Sweden' },
					{ countryCode: '+41', code: 'CH', country: 'Switzerland' },
					{ countryCode: '+971', code: 'AE', country: 'United Arab Emirates' },
					{ countryCode: '+44', code: 'GB', country: 'United Kingdom' },
					{ countryCode: '+593', code: 'EC', country: 'Ecuador' },
					{ countryCode: '+376', code: 'AD', country: 'Andorra' },
					{ countryCode: '+93', code: 'AF', country: 'Afghanistan' },
					{ countryCode: '+1268', code: 'AG', country: 'Antigua and Barbuda' },
					{ countryCode: '+1264', code: 'AI', country: 'Anguilla' },
					{ countryCode: '+355', code: 'AL', country: 'Albania' },
					{ countryCode: '+374', code: 'AM', country: 'Armenia' },
					{ countryCode: '+244', code: 'AO', country: 'Angola' },
					{ countryCode: '+672', code: 'AQ', country: 'Antarctica' },
					{ countryCode: '+54', code: 'AR', country: 'Argentina' },
					{ countryCode: '+1684', code: 'AS', country: 'American Samoa' },
					{ countryCode: '+297', code: 'AW', country: 'Aruba' },
					{ countryCode: '+994', code: 'AZ', country: 'Azerbaijan' },
					{ countryCode: '+387', code: 'BA', country: 'Bosnia and Herzegovina' },
					{ countryCode: '+1246', code: 'BB', country: 'Barbados' },
					{ countryCode: '+880', code: 'BD', country: 'Bangladesh' },
					{ countryCode: '+226', code: 'BF', country: 'Burkina Faso' },
					{ countryCode: '+973', code: 'BH', country: 'Bahrain' },
					{ countryCode: '+257', code: 'BI', country: 'Burundi' },
					{ countryCode: '+229', code: 'BJ', country: 'Benin' },
					{ countryCode: '+1441', code: 'BM', country: 'Bermuda' },
					{ countryCode: '+673', code: 'BN', country: 'Brunei Darussalam' },
					{ countryCode: '+591', code: 'BO', country: 'Bolivia' },
					{ countryCode: '+1242', code: 'BS', country: 'Bahamas' },
					{ countryCode: '+975', code: 'BT', country: 'Bhutan' },
					{ countryCode: '+267', code: 'BW', country: 'Botswana' },
					{ countryCode: '+375', code: 'BY', country: 'Belarus' },
					{ countryCode: '+501', code: 'BZ', country: 'Belize' },
					{ countryCode: '+61', code: 'CC', country: 'Cocos (Keeling) Islands' },
					{ countryCode: '+236', code: 'CF', country: 'Central African Republic' },
					{ countryCode: '+242', code: 'CG', country: 'Congo' },
					{ countryCode: '+237', code: 'CM', country: 'Cameroon' },
					{ countryCode: '+56', code: 'CL', country: 'Chile' },
					{ countryCode: '+86', code: 'CN', country: 'China' },
					{ countryCode: '+238', code: 'CV', country: 'Cape Verde' },
					{ countryCode: '+235', code: 'TD', country: 'Chad' },
					{ countryCode: '+61', code: 'CX', country: 'Christmas Island' },
					{ countryCode: '+225', code: 'CI', country: 'Côte dIvoire / Ivory Coast' },
					{ countryCode: '+57', code: 'CO', country: 'Colombia' },
					{ countryCode: '+682', code: 'CK', country: 'Cook Islands' },
					{ countryCode: '+506', code: 'CR', country: 'Costa Rica' },
					{ countryCode: '+53', code: 'CU', country: 'Cuba' },
					{ countryCode: '+357', code: 'CY', country: 'Cyprus' },
					{ countryCode: '+420', code: 'CZ', country: 'Czech Republic' },
					{ countryCode: '+253', code: 'DJ', country: 'Djibouti' },
					{ countryCode: '+45', code: 'DK', country: 'Denmark' },
					{ countryCode: '+1767', code: 'DM', country: 'Dominica' },
					{ countryCode: '+1', code: 'DO', country: 'Dominican Republic' },
					{ countryCode: '+213', code: 'DZ', country: 'Algeria' },
					{ countryCode: '+20', code: 'EG', country: 'Egypt' },
					{ countryCode: '+291', code: 'ER', country: 'Eritrea' },
					{ countryCode: '+268', code: 'SZ', country: 'Eswatini' },
					{ countryCode: '+34', code: 'ES', country: 'Spain' },
					{ countryCode: '+251', code: 'ET', country: 'Ethiopia' },
					{ countryCode: '+679', code: 'FJ', country: 'Fiji' },
					{ countryCode: '+500', code: 'FK', country: 'Falkland Islands (Malvinas)' },
					{ countryCode: '+691', code: 'FM', country: 'Micronesia, Federated States of Micronesia' },
					{ countryCode: '+298', code: 'FO', country: 'Faroe Islands' },
					{ countryCode: '+241', code: 'GA', country: 'Gabon' },
					{ countryCode: '1473', code: 'GD', country: 'Grenada' },
					{ countryCode: '+995', code: 'GE', country: 'Georgia' },
					{ countryCode: '594', code: 'GF', country: 'French Guiana' },
					{ countryCode: '+233', code: 'GH', country: 'Ghana' },
					{ countryCode: '+350', code: 'GI', country: 'Gibraltar' },
					{ countryCode: '+299', code: 'GL', country: 'Greenland' },
					{ countryCode: '+220', code: 'GM', country: 'Gambia' },
					{ countryCode: '+224', code: 'GN', country: 'Guinea' },
					{ countryCode: '+590', code: 'GP', country: 'Guadeloupe' },
					{ countryCode: '+240', code: 'GQ', country: 'Equatorial Guinea' },
					{ countryCode: '+500', code: 'GS', country: 'South Georgia and the South Sandwich Islands' },
					{ countryCode: '+502', code: 'GT', country: 'Guatemala' },
					{ countryCode: '+1671', code: 'GU', country: 'Guam' },
					{ countryCode: '+245', code: 'GW', country: 'Guinea-Bissau' },
					{ countryCode: '+592', code: 'GY', country: 'Guyana' },
					{ countryCode: '+504', code: 'HN', country: 'Honduras' },
					{ countryCode: '+385', code: 'HR', country: 'Croatia' },
					{ countryCode: '+509', code: 'HT', country: 'Haiti' },
					{ countryCode: '+972', code: 'IL', country: 'Israel' },
					{ countryCode: '+246', code: 'IO', country: 'British Indian Ocean Territory' },
					{ countryCode: '+964', code: 'IQ', country: 'Iraq' },
					{ countryCode: '+98', code: 'IR', country: 'Iran' },
					{ countryCode: '+354', code: 'IS', country: 'Iceland' },
					{ countryCode: '+1876', code: 'JM', country: 'Jamaica' },
					{ countryCode: '+962', code: 'JO', country: 'Jordan' },
					{ countryCode: '+254', code: 'KE', country: 'Kenya' },
					{ countryCode: '+996', code: 'KG', country: 'Kyrgyzstan' },
					{ countryCode: '+855', code: 'KH', country: 'Cambodia' },
					{ countryCode: '+686', code: 'KI', country: 'Kiribati' },
					{ countryCode: '+269', code: 'KM', country: 'Comoros' },
					{ countryCode: '+1869', code: 'KN', country: 'Saint Kitts and Nevis' },
					{ countryCode: '+850', code: 'KP', country: 'Korea, Democratic Peoples Republic of Korea' },
					{ countryCode: '+82', code: 'KR', country: 'Korea, Republic of South Korea' },
					{ countryCode: '+965', code: 'KW', country: 'Kuwait' },
					{ countryCode: '+1345', code: 'KY', country: 'Cayman Islands' },
					{ countryCode: '+77', code: 'KZ', country: 'Kazakhstan' },
					{ countryCode: '+856', code: 'LA', country: 'Laos' },
					{ countryCode: '+961', code: 'LB', country: 'Lebanon' },
					{ countryCode: '+1758', code: 'LC', country: 'Saint Lucia' },
					{ countryCode: '+423', code: 'LI', country: 'Liechtenstein' },
					{ countryCode: '+231', code: 'LR', country: 'Liberia' },
					{ countryCode: '+266', code: 'LS', country: 'Lesotho' },
					{ countryCode: '+218', code: 'LY', country: 'Libya' },
					{ countryCode: '+212', code: 'MA', country: 'Morocco' },
					{ countryCode: '+373', code: 'MD', country: 'Moldova' },
					{ countryCode: '+382', code: 'ME', country: 'Montenegro' },
					{ countryCode: '+261', code: 'MG', country: 'Madagascar' },
					{ countryCode: '+692', code: 'MH', country: 'Marshall Islands' },
					{ countryCode: '+223', code: 'ML', country: 'Mali' },
					{ countryCode: '+95', code: 'MM', country: 'Myanmar' },
					{ countryCode: '+976', code: 'MN', country: 'Mongolia' },
					{ countryCode: '+853', code: 'MO', country: 'Macau' },
					{ countryCode: '+596', code: 'MQ', country: 'Martinique' },
					{ countryCode: '+222', code: 'MR', country: 'Mauritania' },
					{ countryCode: '+230', code: 'MU', country: 'Mauritius' },
					{ countryCode: '+960', code: 'MV', country: 'Maldives' },
					{ countryCode: '+265', code: 'MW', country: 'Malawi' },
					{ countryCode: '+1664', code: 'MS', country: 'Montserrat' },
					{ countryCode: '+258', code: 'MZ', country: 'Mozambique' },
					{ countryCode: '+264', code: 'NA', country: 'Namibia' },
					{ countryCode: '+674', code: 'NR', country: 'Nauru' },
					{ countryCode: '+687', code: 'NC', country: 'New Caledonia' },
					{ countryCode: '+977', code: 'NP', country: 'Nepal' },
					{ countryCode: '+227', code: 'NE', country: 'Niger' },
					{ countryCode: '+505', code: 'NI', country: 'Nicaragua' },
					{ countryCode: '+683', code: 'NU', country: 'Niue' },
					{ countryCode: '+672', code: 'NF', country: 'Norfolk Island' },
					{ countryCode: '+389', code: 'MK', country: 'North Macedonia' },
					{ countryCode: '+1670', code: 'MP', country: 'Northern Mariana Islands' },
					{ countryCode: '+968', code: 'OM', country: 'Oman' },
					{ countryCode: '+92', code: 'PK', country: 'Pakistan' },
					{ countryCode: '+507', code: 'PA', country: 'Panama' },
					{ countryCode: '+51', code: 'PE', country: 'Peru' },
					{ countryCode: '+689', code: 'PF', country: 'French Polynesia' },
					{ countryCode: '+675', code: 'PG', country: 'Papua New Guinea' },
					{ countryCode: '+508', code: 'PM', country: 'Saint Pierre and Miquelon' },
					{ countryCode: '+680', code: 'PW', country: 'Palau' },
					{ countryCode: '+595', code: 'PY', country: 'Paraguay' },
					{ countryCode: '+872', code: 'PN', country: 'Pitcairn' },
					{ countryCode: '+1', code: 'PR', country: 'Puerto Rico' },
					{ countryCode: '+974', code: 'QA', country: 'Qatar' },
					{ countryCode: '+262', code: 'RE', country: 'Reunion' },
					{ countryCode: '+7', code: 'RU', country: 'Russia' },
					{ countryCode: '+250', code: 'RW', country: 'Rwanda' },
					{ countryCode: '+685', code: 'WS', country: 'Samoa' },
					{ countryCode: '+966', code: 'SA', country: 'Saudi Arabia' },
					{ countryCode: '+381', code: 'RS', country: 'Serbia' },
					{ countryCode: '+248', code: 'SC', country: 'Seychelles' },
					{ countryCode: '+677', code: 'SB', country: 'Solomon Islands' },
					{ countryCode: '+249', code: 'SD', country: 'Sudan' },
					{ countryCode: '+290', code: 'SH', country: 'Saint Helena, Ascension and Tristan Da Cunha' },
					{ countryCode: '+47', code: 'SJ', country: 'Svalbard and Jan Mayen' },
					{ countryCode: '+232', code: 'SL', country: 'Sierra Leone' },
					{ countryCode: '+378', code: 'SM', country: 'San Marino' },
					{ countryCode: '+221', code: 'SN', country: 'Senegal' },
					{ countryCode: '+252', code: 'SO', country: 'Somalia' },
					{ countryCode: '+94', code: 'LK', country: 'Sri Lanka' },
					{ countryCode: '+597', code: 'SR', country: 'Suriname' },
					{ countryCode: '+211', code: 'SS', country: 'South Sudan' },
					{ countryCode: '+239', code: 'ST', country: 'Sao Tome and Principe' },
					{ countryCode: '+503', code: 'SV', country: 'El Salvador' },
					{ countryCode: '+963', code: 'SY', country: 'Syrian Arab Republic' },
					{ countryCode: '+66', code: 'TH', country: 'Thailand' },
					{ countryCode: '+992', code: 'TJ', country: 'Tajikistan' },
					{ countryCode: '+670', code: 'TL', country: 'Timor-Leste' },
					{ countryCode: '+886', code: 'TW', country: 'Taiwan' },
					{ countryCode: '+255', code: 'TZ', country: 'Tanzania, United Republic of Tanzania' },
					{ countryCode: '+1868', code: 'TT', country: 'Trinidad and Tobago' },
					{ countryCode: '+228', code: 'TG', country: 'Togo' },
					{ countryCode: '+690', code: 'TK', country: 'Tokelau' },
					{ countryCode: '+676', code: 'TO', country: 'Tonga' },
					{ countryCode: '+216', code: 'TN', country: 'Tunisia' },
					{ countryCode: '+90', code: 'TR', country: 'Turkey' },
					{ countryCode: '+993', code: 'TM', country: 'Turkmenistan' },
					{ countryCode: '+1649', code: 'TC', country: 'Turks and Caicos Islands' },
					{ countryCode: '+688', code: 'TV', country: 'Tuvalu' },
					{ countryCode: '+256', code: 'UG', country: 'Uganda' },
					{ countryCode: '+380', code: 'UA', country: 'Ukraine' },
					{ countryCode: '+598', code: 'UY', country: 'Uruguay' },
					{ countryCode: '+998', code: 'UZ', country: 'Uzbekistan' },
					{ countryCode: '+379', code: 'VA', country: 'Holy See (Vatican City State)' },
					{ countryCode: '+1784', code: 'VC', country: 'Saint Vincent and the Grenadines' },
					{ countryCode: '+678', code: 'VU', country: 'Vanuatu' },
					{ countryCode: '+58', code: 'VE', country: 'Venezuela, Bolivarian Republic of Venezuela' },
					{ countryCode: '+84', code: 'VN', country: 'Vietnam' },
					{ countryCode: '+1284', code: 'VG', country: 'Virgin Islands, British' },
					{ countryCode: '+1340', code: 'VI', country: 'Virgin Islands, U.S.' },
					{ countryCode: '+681', code: 'WF', country: 'Wallis and Futuna' },
					{ countryCode: '+967', code: 'YE', country: 'Yemen' },
					{ countryCode: '+260', code: 'ZM', country: 'Zambia' },
					{ countryCode: '+263', code: 'ZW', country: 'Zimbabwe' },
					{ countryCode: '', code: '', country: 'Other' },
				],
			}
		},
		async mounted() {
			this.localPhone = this.phone
			this.countryCodeSelectedIndex = this.clearCountryCodes.findIndex(item => item.countryCode == this.phoneCountryCode)
		},
		destroyed() {
			if (this.debounceTimeout !== null) {
				clearInterval(this.debounceTimeout)
				this.debounceTimeout = null
			}
		},
		emits: ['update-phone'],
		methods: {
			selectCountry(index) {
				this.countryCodeSelectedIndex = index
				this.switchCountryDropdown()
			},
			switchCountryDropdown() {
				this.countryDropdown = !this.countryDropdown && !this.disabled
			},
			isShowCountry(item) {
				if (this.searchCountry == '') return true
				if (
					item.country.toLowerCase().includes(this.searchCountry.toLowerCase()) ||
					item.code.toLowerCase().includes(this.searchCountry.toLowerCase())
				)
					return true
				return false
			},
		},
		watch: {
			phone: {
				immediate: true,
				handler(newVal) {
					this.localPhone = newVal
				},
			},
			localPhone: {
				immediate: true,
				handler(newVal, oldVal) {
					if (oldVal !== newVal) {
						this.$emit('update-phone', {
							number: `${newVal}`.replace(/\D/gi, ''),
							countryCode: this.selectedCountryCodeItem.countryCode,
							valid: true,
							possible: this.possible,
						})
					}
				},
			},
			countryCodeSelectedIndex: {
				immediate: true,
				handler(newVal, oldVal) {
					if (newVal && oldVal !== newVal) {
						this.$emit('update-phone', {
							number: this.localPhone,
							countryCode: this.selectedCountryCodeItem.countryCode,
							valid: true,
							possible: this.possible,
						})
					}
				},
			},
		},
	}
</script>
<style lang="scss" scoped>
	.country-2-letter-code {
		// color: rgba(41, 42, 51, 0.2);
		margin-right: 5px;
	}
	.phone-holder {
		display: flex;

		.phone-country {
			display: flex !important;
			justify-content: center;
			flex: 0 !important;
			margin-right: 10px;
			min-width: 90px !important;
		}

		.phone-number {
			width: 70%;
		}
	}

	.dropbtn {
		padding: 16px;
		font-size: 16px;
		border: none;
		cursor: pointer;
	}

	.flag {
		margin-left: 5px;
		// margin-right: 5px;
	}

	/* The container <div> - needed to position the dropdown content */
	.dropdown {
		// position: relative;
		display: inline-block;
		overflow: visible;
	}

	.country-menu {
		font-size: 87%;
		display: block;
		background-color: white;
		overflow: auto;
		z-index: 20 !important;
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;

		@media (min-width: 1024px) {
			position: relative;
			height: 400px;
			width: auto;
		}
	}

	/* Dropdown Content (Hidden by Default) */
	.dropdown-content {
		margin-left: auto;
		margin-right: auto;
		padding-left: 15px;
		padding-right: 15px;
		padding-top: 10px;
		max-width: 475px;

		@media (min-width: 1024px) {
			max-width: 100%;
		}

		.country {
			max-width: 100px;
		}

		.flag-selector-item {
			cursor: pointer;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			padding-top: 10px;
			padding-bottom: 10px;
		}

		.country-back-button {
			width: 15px;
			margin: 7px;
		}
	}
</style>
