/* eslint-disable consistent-return, no-unused-vars */
import { getPreviewImage, isImageProcessing, preloadImage, getOptimizeOptions, getOptimizeImageUrl, checkImage } from './OptimizationImageTools.js'
import { emptyImage, emptyImage73 } from './utils.js'

export default {
	name: 'ImagePreview',
	props: {
		source: {
			type: String,
			default: () => '',
		},
		alt: {
			type: String,
			default: () => '',
		},
		staticClass: {
			type: String,
			default: () => '',
		},
		staticStyle: {
			type: String,
			default: () => '',
		},
		format: {
			type: String,
			default: () => 'webp',
		},
		quality: {
			type: Number,
			default: () => 100,
		},
		width: {
			type: Number,
			default: () => -1,
		},
		height: {
			type: Number,
			default: () => -1,
		},
		retry: {
			type: Number,
			default: () => -1,
		},
	},
	computed: {
		class() {
			if (this.staticClass && this.staticClass !== '') {
				return `base-preview-image ${this.staticClass}`
			}
			return 'base-preview-image'
		},
		style() {
			if (this.staticStyle && this.staticStyle !== '') {
				return this.staticStyle
			}
		},
		src() {
			const imgSrc =
				typeof this.imageSource === 'undefined' || this.imageSource === null || this.imageSource === '' ? emptyImage73 : this.imageSource
			return this.init ? emptyImage73 : imgSrc
		},
	},
	data() {
		return {
			init: true,
			imageSource: '',
			isImageReady: false,
			retryGetPreviewInterval: null,
			startedCheckImage: false,
			retryCount: 0,
		}
	},
	mounted() {
		this.preloadImage()
	},
	methods: {
		async checkImagePreview(src) {
			if (!this.startedCheckImage) {
				this.startedCheckImage = true
				this.isImageReady = await checkImage(src)
				this.startedCheckImage = false
			}
			if (this.isImageReady) {
				this.clearCheckImageInterval()
				getPreviewImage(src, this.$refs.image)
					.then(image => {
						this.imageSource = image
					})
					.catch(() => {
						this.checkSourceImage().then(image => {
							this.imageSource = image
						})
					})
				this.$emit('load')
			}
		},
		async preloadImage() {
			if (!isImageProcessing(this.source)) {
				this.init = false
				this.imageSource = this.source || emptyImage73
				this.$emit('isLoading', false)
				return
			}

			let assetsDomain = ''
			// Vue-admin config
			if (this.$configSettings && this.$configSettings?.assetsDomain !== '') {
				assetsDomain = this.$configSettings.assetsDomain
			}

			// Nuxt config
			if (this.$config && this.$config?.assetsDomain !== '') {
				assetsDomain = this.$config.assetsDomain
			}

			const url = new URL(this.source)
			if (!url.host.endsWith(assetsDomain)) {
				this.init = false
				this.imageSource = this.source || emptyImage73
				this.$emit('isLoading', false)
				return
			}

			const optimizeImageOptions = getOptimizeOptions(this)
			optimizeImageOptions.assetsDomain = assetsDomain
			const optimizeImageSrc = await getOptimizeImageUrl(this.source, { ...optimizeImageOptions })
			try {
				preloadImage(optimizeImageSrc)
					.then(() => {
						this.init = false
						this.imageSource = optimizeImageSrc
						this.$emit('load')
						this.$emit('isLoading', false)
						return true
					})
					.catch(async () => {
						this.init = false
						if (this.retry > 0) {
							await this.checkImagePreview(optimizeImageSrc)
							if (!this.isImageReady) {
								this.retryGetPreviewImage(optimizeImageSrc)
							} else {
								this.$emit('load')
								this.$emit('isLoading', false)
							}
						} else {
							this.imageSource = await this.checkSourceImage()
							this.$emit('load')
							this.$emit('isLoading', false)
						}
						return true
					})
			} catch {
				this.imageSource = await this.checkSourceImage()
				this.$emit('load')
				this.$emit('isLoading', false)
				return true
			}
		},
		async retryGetPreviewImage(src) {
			if (!this.isImageReady && this.retry - this.retryCount > 0) {
				await this.checkImagePreview(src)
				if (!this.isImageReady) {
					this.clearCheckImageInterval()
					this.retryGetPreviewInterval = setInterval(() => {
						this.retryCount += 1
						this.retryGetPreviewImage(src)
					}, 2000)
				}
			} else if (!this.isImageReady) {
				this.clearCheckImageInterval()
				this.imageSource = await this.checkSourceImage()
				this.$emit('load')
				this.$emit('isLoading', false)
			} else {
				this.clearCheckImageInterval()
			}
		},
		async checkSourceImage() {
			this.init = false
			const imageSourceReady = await checkImage(this.source)
			return imageSourceReady ? this.source || emptyImage73 : emptyImage73
		},
		clearCheckImageInterval() {
			if (this.retryGetPreviewInterval !== null) {
				clearInterval(this.retryGetPreviewInterval)
				this.retryGetPreviewInterval = null
			}
		},
	},
	watch: {
		source() {
			this.init = true
			this.$emit('isLoading', true)
			this.clearCheckImageInterval()
			this.preloadImage()
		},
	},
	render(h) {
		return h('img', { ref: 'image', class: this.class, style: this.style, attrs: { src: this.src, alt: this.alt } })
	},
}
